.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  animation: flickerLG infinite 20s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: flickerLG infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(255, 255, 255, 0.64);
}



@keyframes flickerLG {
	0% {
		opacity: 0.19;
	}
	5% {
		opacity: 0.2;
	}
	10% {
		opacity: 0.25;
	}
	15% {
		opacity: 0.35;
	}
	25% {
		opacity: 0.2;
	}
	30% {
		opacity: 0.4;
	}
	35% {
		opacity: 0.1;
	}
	40% {
		opacity: 0.25;
	}
	45% {
		opacity: 0.2;
	}
	60% {
		opacity: 0.4;
	}
	70% {
		opacity: 0.35;
	}
	80% {
		opacity: 0.4;
	}
	90% {
		opacity: 0.2;
	}
	100% {
		opacity: 0.4;
	}
}
